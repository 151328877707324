function ModulesCards() {
    return (
        <div className="mt-[2rem] flex flex-row flex-wrap sm:justify-between justify-center">
            <div className="w-[20rem] sm:h-[12rem] h-[7rem] bg-green-900 rounded-2xl m-4 flex flex-col justify-center items-center">
                <h1 className="font-extrabold sm:text-3xl text-3xl text-center text-gray-200">Confira os nossos diferenciais!</h1>
            </div>
            <div className="w-[20rem] h-[12rem] bg-gray-200 rounded-2xl m-4 flex flex-col justify-start items-center">
                <h1 className="font-bold text-2xl text-green-900 mb-[1rem] mt-[1.5rem]">Impacto Social</h1>
                <p className="ml-[0.5rem] mr-[0.5rem] font-normal text-xl text-center text-green-900">9 a cada 10 <b>pessoas</b> estão <b>satisfeitas</b> com a sensação térmica</p>
            </div>
            <div className="w-[20rem] h-[12rem] bg-gray-200 rounded-2xl m-4 flex flex-col justify-start items-center">
                <h1 className="font-bold text-2xl text-green-900 mb-[1rem] mt-[1.5rem]">Impacto Ambiental</h1>
                <p className="ml-[0.5rem] mr-[0.5rem] font-normal text-xl text-center text-green-900"><b>Zero impacto</b> no consumo de combustível e emissões de poluentes</p>
            </div>
            <div className="w-[20rem] h-[12rem] bg-gray-200 rounded-2xl m-4 flex flex-col justify-start items-center">
                <h1 className="font-bold text-2xl text-green-900 mb-[1rem] mt-[1.5rem]">Saúde</h1>
                <p className="ml-[0.5rem] mr-[0.5rem] font-normal text-xl text-center text-green-900">Bom nível de qualidade de ar conforme <b>Resolução Nº09 da ANVISA</b></p>
            </div>
            <div className="w-[20rem] h-[12rem] bg-gray-200 rounded-2xl m-4 flex flex-col justify-start items-center">
                <h1 className="font-bold text-2xl text-green-900 mb-[1rem] mt-[1.5rem]">Segurança</h1>
                <p className="ml-[0.5rem] mr-[0.5rem] font-normal text-xl text-center text-green-900">Seguro por atender a <b>NBR 15570</b></p>
            </div>
            <div className="w-[20rem] h-[12rem] bg-gray-200 rounded-2xl m-4 flex flex-col justify-start items-center">
                <h1 className="font-bold text-2xl text-green-900 mb-[1rem] mt-[1.5rem]">Econômico</h1>
                <p className="ml-[0.5rem] mr-[0.5rem] font-normal text-xl text-center text-green-900"><b>Baixo custo</b> de aquisição e manutenção</p>
            </div>
        </div>
    );
}

export default ModulesCards;