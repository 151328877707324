import React, { useState } from 'react';

type FaqProps = {
    question: string,
    answer: string,
    isOpen: boolean,
    toggle: (data: any) => void
} 

const FaqItem = ({ question, answer, isOpen, toggle }: FaqProps) => (
  <div className="mb-2">
    <button
      className="w-full flex items-center justify-between p-2 bg-gray-100 hover:bg-gray-300 focus:outline-none rounded-lg"
      onClick={toggle}
    >
      <div className="font-semibold text-green-900">{question}</div>
      <div>{isOpen ? '-' : '+'}</div>
    </button>
    {isOpen && (
      <div className="p-[1rem] bg-white text-left">
        <p>{answer}</p>
      </div>
    )}
  </div>
);

const Faq = () => {
  const [faqs, setFaqs] = useState([
    {
      question: 'Como funciona?',
      answer: 'O Arejabus utiliza a própria movimentação do ônibus para promover a renovação de ar por meio de dispositivos naturais e nos momentos de baixa velocidade, um controlador aciona a ventilação forçada para manter o bom nível de sensação térmica e qualidade de ar.',
      isOpen: false,
    },
    {
      question: 'Funciona em dias de chuva?',
      answer: 'Sim, o fluxo de ar acontece independentemente das condições climáticas e operacionais.',
      isOpen: false,
    },
    {
      question: 'Como podemos aplicar o produto?',
      answer: 'A equipe da Areja desenvolve um projeto de climatização para cada modelo de ônibus, possibilitando que o Arejabus seja aplicado em veículos novos ou usados.',
      isOpen: false,
    },
  ]);

  const toggleFaq = (index: number) => {
    const newFaqs = [...faqs];
    newFaqs[index].isOpen = !faqs[index].isOpen;
    setFaqs(newFaqs);
  };

  return (
    <div className="w-[100%] p-4 mt-[5rem]">
      <h1 className="text-green-900 font-bold text-5xl mb-[3rem]">Perguntas Frequentes</h1>
      {faqs.map((faq, index) => (
        <FaqItem
          key={index}
          question={faq.question}
          answer={faq.answer}
          isOpen={faq.isOpen}
          toggle={() => toggleFaq(index)}
        />
      ))}
    </div>
  );
};

export default Faq;